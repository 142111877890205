<!-- Deprecated in favour of separate files for SMS and Whatsapp and also to implement new providers for each platform in the future-->
<template>
  <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.channelName.$error }">
        {{ $t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.LABEL') }}
        <input
          v-model.trim="channelName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.PLACEHOLDER')"
          @blur="$v.channelName.$touch"
        />
        <span v-if="$v.channelName.$error" class="message">{{
          $t('INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.ERROR')
        }}</span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label
        v-if="useMessagingService"
        :class="{ error: $v.messagingServiceSID.$error }"
      >
        {{ $t('INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.LABEL') }}
        <input
          v-model.trim="messagingServiceSID"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.PLACEHOLDER')
          "
          @blur="$v.messagingServiceSID.$touch"
        />
        <span v-if="$v.messagingServiceSID.$error" class="message">{{
          $t('INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.ERROR')
        }}</span>
      </label>
    </div>

    <div v-if="!useMessagingService" class="medium-8 columns">
      <label
        :class="{
          // eslint-disable-next-line
          error: $v.phoneNumber.$error ||
            // eslint-disable-next-line
            (!isPhoneNumberValid && !validatingPhoneNumber),
        }"
      >
        {{ $t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.LABEL') }}
        <input
          v-model.trim="phoneNumber"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.PHONE_NUMBER.PLACEHOLDER')
          "
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="message">{{
          $t('INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.ERROR')
        }}</span>

        <span
          v-if="!isPhoneNumberValid && !validatingPhoneNumber"
          class="message"
        >
          Entered phone number is already being used
        </span>
      </label>
    </div>

    <div class="medium-8 columns messagingServiceHelptext">
      <label for="useMessagingService">
        <input
          id="useMessagingService"
          v-model="useMessagingService"
          type="checkbox"
          class="checkbox"
        />
        {{
          $t(
            'INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.USE_MESSAGING_SERVICE'
          )
        }}
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.accountSID.$error }">
        {{ $t('INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.LABEL') }}
        <input
          v-model.trim="accountSID"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.PLACEHOLDER')"
          @blur="$v.accountSID.$touch"
        />
        <span v-if="$v.accountSID.$error" class="message">{{
          $t('INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.ERROR')
        }}</span>
      </label>
    </div>
    <div class="medium-8 columns messagingServiceHelptext">
      <label for="useAPIKey">
        <input
          id="useAPIKey"
          v-model="useAPIKey"
          type="checkbox"
          class="checkbox"
        />
        {{ $t('INBOX_MGMT.ADD.TWILIO.API_KEY.USE_API_KEY') }}
      </label>
    </div>
    <div v-if="useAPIKey" class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.apiKeySID.$error }">
        {{ $t('INBOX_MGMT.ADD.TWILIO.API_KEY.LABEL') }}
        <input
          v-model.trim="apiKeySID"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.TWILIO.API_KEY.PLACEHOLDER')"
          @blur="$v.apiKeySID.$touch"
        />
        <span v-if="$v.apiKeySID.$error" class="message">{{
          $t('INBOX_MGMT.ADD.TWILIO.API_KEY.ERROR')
        }}</span>
      </label>
    </div>
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.authToken.$error }">
        {{ $t(`INBOX_MGMT.ADD.TWILIO.${authTokeni18nKey}.LABEL`) }}
        <input
          v-model.trim="authToken"
          type="text"
          :placeholder="
            $t(`INBOX_MGMT.ADD.TWILIO.${authTokeni18nKey}.PLACEHOLDER`)
          "
          @blur="$v.authToken.$touch"
        />
        <span v-if="$v.authToken.$error" class="message">
          {{ $t(`INBOX_MGMT.ADD.TWILIO.${authTokeni18nKey}.ERROR`) }}
        </span>
      </label>
    </div>

    <div class="w-full">
      <woot-submit-button
        :disabled="!isPhoneNumberValid || validatingPhoneNumber"
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.TWILIO.SUBMIT_BUTTON')"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  mixins: [alertMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newInboxID: '',
      phoneNumber: '',
      isPhoneNumberValid: true,
      validatingPhoneNumber: false,
      phoneNumberValidationTimeout: null,
      accountSID: '',
      apiKeySID: '',
      authToken: '',
      medium: this.type,
      channelName: '',
      messagingServiceSID: '',
      useMessagingService: false,
      useAPIKey: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    authTokeni18nKey() {
      return this.useAPIKey ? 'API_KEY_SECRET' : 'AUTH_TOKEN';
    },
  },

  validations() {
    let validations = {
      channelName: { required },

      authToken: { required },
      accountSID: { required },
      medium: { required },
    };
    if (this.phoneNumber) {
      validations = {
        ...validations,
        phoneNumber: { required, isPhoneE164OrEmpty },
        messagingServiceSID: {},
      };
    } else {
      validations = {
        ...validations,
        messagingServiceSID: { required },
        phoneNumber: {},
      };
    }

    if (this.useAPIKey) {
      validations = {
        ...validations,
        apiKeySID: { required },
      };
    }
    return validations;
  },
  watch: {
    phoneNumber(value) {
      if (this.phoneNumberValidationTimeout) {
        clearTimeout(this.phoneNumberValidationTimeout);
      }

      if (!value) {
        return;
      }

      this.validatingPhoneNumber = true;

      this.phoneNumberValidationTimeout = setTimeout(() => {
        this.validatePhoneNumber(value);
      }, 3000);
    },
  },
  mounted() {
    window.addEventListener('message', this.listenMessages);
  },

  destroyed() {
    window.removeEventListener('message', this.listenMessages);
  },
  methods: {
    validatePhoneNumber(value) {
      window.parent.postMessage(
        { type: 'VALIDATE_NUMBER', payload: { number: value } },
        '*'
      );
    },

    listenMessages(event) {
      switch (event.data.type) {
        case 'VALIDATE_NUMBER':
          this.isPhoneNumberValid = event.data.payload.isValid;
          this.validatingPhoneNumber = false;
          break;
        case 'ASSIGN_NUMBER_TO_INBOX_SUCCESS':
          this.redirect(this.newInboxID);
          break;
        case 'ASSIGN_NUMBER_TO_INBOX_ERROR':
          this.deleteChannel(this.newInboxID);
          this.showAlert('Inbox could not be created. Try again');
          break;
        default:
          break;
      }
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const twilioChannel = await this.$store.dispatch(
          'inboxes/createTwilioChannel',
          {
            twilio_channel: {
              name: this.channelName,
              medium: this.medium,
              account_sid: this.accountSID,
              api_key_sid: this.apiKeySID,
              auth_token: this.authToken,
              messaging_service_sid: this.messagingServiceSID,
              phone_number: this.messagingServiceSID
                ? null
                : `+${this.phoneNumber.replace(/\D/g, '')}`,
            },
          }
        );

        this.newInboxID = twilioChannel.id;

        if (this.messagingServiceSID) {
          this.redirect(this.newInboxID);
        } else {
          window.parent.postMessage(
            {
              type: 'ASSIGN_NUMBER_TO_INBOX',
              payload: {
                id: twilioChannel.id,
                provider: 'twilio',
                number: this.phoneNumber,
              },
            },
            '*'
          );
        }
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.TWILIO.API.ERROR_MESSAGE'));
      }
    },

    async deleteChannel(id) {
      try {
        await this.$store.dispatch('inboxes/delete', id);
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE')
        );
      }
    },

    redirect(id) {
      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.messagingServiceHelptext {
  margin-top: -10px;
  margin-bottom: 15px;

  .checkbox {
    margin: 0px 4px;
  }
}
</style>

import { createConsumer } from '@rails/actioncable';
import { BUS_EVENTS } from 'shared/constants/busEvents';

const PRESENCE_INTERVAL = 20000;

const STATE_CABLE_CONNECTING = 0;
const STATE_CABLE_CONNECTED = 1;
const STATE_CABLE_DISCONNECTED = 2;
const STATE_CABLE_RECONNECTED = 3;

class BaseActionCableConnector {
  constructor(app, pubsubToken, websocketHost = '') {
    const websocketURL = websocketHost ? `${websocketHost}/cable` : undefined;
    this.cableState = STATE_CABLE_CONNECTING;
    this.consumer = createConsumer(websocketURL);
    this.subscription = this.consumer.subscriptions.create(
      {
        channel: 'RoomChannel',
        pubsub_token: pubsubToken,
        account_id: app.$store.getters.getCurrentAccountId,
        user_id: app.$store.getters.getCurrentUserID,
      },
      {
        updatePresence() {
          this.perform('update_presence');
        },
        received: this.onReceived,
        disconnected: this.onDisconnected,
        connected: this.onConnected,
      }
    );
    this.app = app;
    this.events = {};
    this.isAValidEvent = () => true;
    this.user_id = app.$store.getters.getCurrentUserID;

    setInterval(() => {
      this.subscription.updatePresence();
    }, PRESENCE_INTERVAL);
  }

  disconnect() {
    this.consumer.disconnect();
  }

  onConnected() {
    switch (this.cableState) {
      case STATE_CABLE_CONNECTING:
        this.cableState = STATE_CABLE_CONNECTED;
        break;
      case STATE_CABLE_DISCONNECTED:
        this.cableState = STATE_CABLE_RECONNECTED;
        if (window.platform === 'ucp') {
          window.parent.postMessage({ type: 'APP_RESET', payload: {} }, '*');
          window.parent.postMessage({ type: 'APP_MOUNTED', payload: {} }, '*');
        }
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onDisconnected() {
    this.cableState = STATE_CABLE_DISCONNECTED;
    window.bus.$emit(BUS_EVENTS.WEBSOCKET_DISCONNECT);
  }

  onReceived = ({ event, data } = {}) => {
    if (this.isAValidEvent(data)) {
      if (this.events[event] && typeof this.events[event] === 'function') {
        this.events[event](data);
        let userHasMsgAssigned =
          this.user_id === data.conversation?.assignee_id;
        let isMsgInbound = this.user_id !== data.sender_id;
        switch (event) {
          case 'message.created':
            if (userHasMsgAssigned && isMsgInbound) {
              window.parent.postMessage(
                {
                  type: 'NEW_MESSAGE_RECEIVED',
                  payload: {
                    who: data.sender.name,
                    content: data.content,
                  },
                },
                '*'
              );
            }
            break;
          case 'conversation.created':
            if (this.user_id === data.meta.assignee.id) {
              if (data.status === 'open') {
                if (data.contact_inbox.source_id.indexOf('whatsapp') !== -1) {
                  let who = data.contact_inbox.source_id.split('whatsapp:')[1];
                  window.parent.postMessage(
                    {
                      type: 'NEW_CONVERSATION_WHATSAPP',
                      payload: {
                        who: who,
                        inbox_id: data.inbox_id,
                      },
                    },
                    '*'
                  );
                }
              }
            }
            break;
          default:
            break;
        }
      }
    }
  };
}

export default BaseActionCableConnector;

<template>
  <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.INBOX_NAME.PLACEHOLDER')
          "
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.INBOX_NAME.ERROR')
        }}</span>
      </label>
    </div>

    <div class="medium-8 columns">
      <label
        :class="{
          // eslint-disable-next-line
          error: $v.phoneNumber.$error ||
            // eslint-disable-next-line
            (!isPhoneNumberValid && !validatingPhoneNumber),
        }"
      >
        {{ $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.PHONE_NUMBER.LABEL') }}
        <input
          v-model.trim="phoneNumber"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.PHONE_NUMBER.PLACEHOLDER')
          "
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.PHONE_NUMBER.ERROR')
        }}</span>
        <span
          v-if="!isPhoneNumberValid && !validatingPhoneNumber"
          class="message"
        >
          Entered phone number is already being used
        </span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.accountId.$error }">
        {{ $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.ACCOUNT_ID.LABEL') }}
        <input
          v-model.trim="accountId"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.ACCOUNT_ID.PLACEHOLDER')
          "
          @blur="$v.accountId.$touch"
        />
        <span v-if="$v.accountId.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.ACCOUNT_ID.ERROR')
        }}</span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.applicationId.$error }">
        {{ $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.APPLICATION_ID.LABEL') }}
        <input
          v-model.trim="applicationId"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.APPLICATION_ID.PLACEHOLDER')
          "
          @blur="$v.applicationId.$touch"
        />
        <span v-if="$v.applicationId.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.APPLICATION_ID.ERROR')
        }}</span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.apiKey.$error }">
        {{ $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.API_KEY.LABEL') }}
        <input
          v-model.trim="apiKey"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.SMS.BANDWIDTH.API_KEY.PLACEHOLDER')"
          @blur="$v.apiKey.$touch"
        />
        <span v-if="$v.apiKey.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.API_KEY.ERROR')
        }}</span>
      </label>
    </div>

    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label :class="{ error: $v.apiSecret.$error }">
        {{ $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.API_SECRET.LABEL') }}
        <input
          v-model.trim="apiSecret"
          type="text"
          :placeholder="
            $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.API_SECRET.PLACEHOLDER')
          "
          @blur="$v.apiSecret.$touch"
        />
        <span v-if="$v.apiSecret.$error" class="message">{{
          $t('INBOX_MGMT.ADD.SMS.BANDWIDTH.API_SECRET.ERROR')
        }}</span>
      </label>
    </div>

    <div class="w-full">
      <woot-submit-button
        :disabled="!isPhoneNumberValid || validatingPhoneNumber"
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.SMS.BANDWIDTH.SUBMIT_BUTTON')"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';

const shouldStartWithPlusSign = (value = '') => value.startsWith('+');

export default {
  mixins: [alertMixin],
  data() {
    return {
      accountId: '',
      apiKey: '',
      apiSecret: '',
      applicationId: '',
      newInboxID: '',
      inboxName: '',
      phoneNumber: '',
      isPhoneNumberValid: true,
      validatingPhoneNumber: false,
      phoneNumberValidationTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, shouldStartWithPlusSign },
    apiKey: { required },
    apiSecret: { required },
    applicationId: { required },
    accountId: { required },
  },
  watch: {
    phoneNumber(value) {
      if (this.phoneNumberValidationTimeout) {
        clearTimeout(this.phoneNumberValidationTimeout);
      }

      if (!value) {
        return;
      }

      this.validatingPhoneNumber = true;

      this.phoneNumberValidationTimeout = setTimeout(() => {
        this.validatePhoneNumber(value);
      }, 3000);
    },
  },
  mounted() {
    window.addEventListener('message', this.listenMessages);
  },

  destroyed() {
    window.removeEventListener('message', this.listenMessages);
  },

  methods: {
    validatePhoneNumber(value) {
      window.parent.postMessage(
        { type: 'VALIDATE_NUMBER', payload: { number: value } },
        '*'
      );
    },
    listenMessages(event) {
      switch (event.data.type) {
        case 'VALIDATE_NUMBER':
          this.isPhoneNumberValid = event.data.payload.isValid;
          this.validatingPhoneNumber = false;
          break;
        case 'ASSIGN_NUMBER_TO_INBOX_SUCCESS':
          this.redirect(this.newInboxID);
          break;
        case 'ASSIGN_NUMBER_TO_INBOX_ERROR':
          this.deleteChannel(this.newInboxID);
          this.showAlert('Inbox could not be created. Try again');
          break;
        default:
          break;
      }
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const smsChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.inboxName,
          channel: {
            type: 'sms',
            phone_number: this.phoneNumber,
            provider_config: {
              api_key: this.apiKey,
              api_secret: this.apiSecret,
              application_id: this.applicationId,
              account_id: this.accountId,
            },
          },
        });

        this.newInboxID = smsChannel.id;

        window.parent.postMessage(
          {
            type: 'ASSIGN_NUMBER_TO_INBOX',
            payload: {
              id: this.newInboxID,
              provider: 'bandwidth',
              number: this.phoneNumber,
            },
          },
          '*'
        );
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.SMS.API.ERROR_MESSAGE'));
      }
    },
    async deleteChannel(id) {
      try {
        await this.$store.dispatch('inboxes/delete', id);
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
    redirect(id) {
      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id: id,
        },
      });
    },
  },
};
</script>
